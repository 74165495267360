<template>
	<Page name="alkylation" 
	title="L'alkylation"
	implementation="1982"
	tonnesHandled="25"
	processingTemperature="35"
	abstract="<p>Cette unité permet d'obtenir une base essence riche en octane. Elle est obtenue par l'addition d'isobutane avec du butène en présence d'un catalyseur. Le travail sur cette unité requiert une tenue spécifique.<p class='illustration'>
		<picture>
			<img class='lazy'
			sizes='(max-width: 1366px) 80px, 200px'
			srcset='
			img/secteur/tenue_alky_sqtvxj_c_scale_w_85.jpg 85w,
			img/secteur/tenue_alky_sqtvxj_c_scale_w_200.jpg 200w'
			src='img/secteur/tenue_alky_sqtvxj_c_scale_w_200.jpg'
			src='static/logo-total.jpg'
			alt=''>
		</picture>
		<div class='legend'>
			<p>Équipement de travail</p>
		</div>"
		animation="static/animation/ALKI.json">
		<picture slot="thumbnail">
			<img class="lazy"
			sizes="(max-width: 480px) 100vw, 480px"
			data-srcset="
			img/secteur/ALKY_lfo90c_c_scale_w_350.jpg 350w,
			img/secteur/ALKY_lfo90c_c_scale_w_480.jpg 480w"
			data-src="img/secteur/ALKY_lfo90c_c_scale_w_480.jpg"
			src="static/logo-total.jpg"
			alt="">
		</picture>
	</Page>
</template>

<script>
	export default {
		name: 'alkylation',
		components: {
			Page: () => import('@/components/Page.vue'),
		},
	}
</script>
